import React from "react"
import { Link as GatsbyLink, navigate as gatsbyNavigate } from "gatsby"
import { useLocation } from "@reach/router"
import clsx from "clsx"

function getLink(to: string, lang: string) {
  return (lang === "en" ? "/en" : "") + to.replace(/^\/en/, '')
}

export function getLang() {
  return localStorage.getItem("artt-dev-language") || "th"
}

export function switchLang() {
  const currentPreferredLanguage = getLang()
  const newLang = currentPreferredLanguage === "th" ? "en" : "th"
  localStorage.setItem("artt-dev-language", newLang)
  gatsbyNavigate(getLink(window.location.pathname, newLang))
}

type Props = {
  to: string,
  lang?: string,
  children: React.ReactNode,
  className?: string,
  activeClassName?: string,
  plain? : boolean,
  [key: string]: any,
}

export default function Link({ to, lang, children, className, activeClassName, plain=false, ...rest }: Props) {

  // for external links, we just link plus target + rel
  if (to[0] !== "/" && to[0] !== "#")
    return <a href={to} target="_blank" rel="noopener noreferrer" {...rest}>{children}</a>
  
  // if lang is not specified, use preferred language; default to Thai
  if (!lang && typeof localStorage !== 'undefined')
    lang = localStorage.getItem("artt-dev-language") || "th"

  // deal with activeClassName, if specified
  // match the location pathname with the link pathname; if match, add activeClassName to className
  if (activeClassName) {
    const pathname = useLocation().pathname
    const isActive = pathname.startsWith(to) || pathname.startsWith(`/en${to}`)
    className = className ? className + (isActive ? " " + activeClassName : "") : (isActive ? activeClassName : "")
  }

  return(
    <GatsbyLink
      to={getLink(to, lang || "th")}
      className={clsx(className, plain && "nodecoration")}
      {...rest}
    >
      {children}
    </GatsbyLink>
  )
}