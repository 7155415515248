import React from "react"
import { useDarkMode } from 'usehooks-ts'

const styles = require("./styles.module.scss")

// adapted from https://codepen.io/aaroniker/pen/KGpXZo

const DayNight = () => {

  const { isDarkMode, toggle } = useDarkMode()

  // https://lukelowrey.com/change-embedded-tweet-dark-theme-to-match-site/
  
  function switchTweetTheme() {
    const tweets = document.querySelectorAll('[data-tweet-id]');
    tweets.forEach(function(tweet) {
        const currentTheme = isDarkMode ? "dark" : "light"
        const targetTheme = currentTheme === "dark" ? "light" : "dark"
        var src = tweet.getAttribute("src");
        tweet.setAttribute("src", src!.replace("theme=" + currentTheme, "theme=" + targetTheme));
    });
  }

  function toggleDarkMode() {
    switchTweetTheme()
    toggle()
  }

  React.useEffect(() => {
    document.documentElement.setAttribute('data-color-mode', isDarkMode ? 'dark' : 'light')
  }, [isDarkMode])

  return(
    <div className={styles.container}>
      <label>
        <input type="checkbox" checked={!isDarkMode} onClick={toggleDarkMode} onChange={() => {}} />
        <div />
      </label>
    </div>
  )

}

export default DayNight