// extracted by mini-css-extract-plugin
export var arttBlue = "#077185";
export var arttOrange = "#d76d07";
export var colorTypes = "\"background\",\"background-contrast\",\"background-alt\",\"primary\",\"secondary\",\"text\",\"heading\",\"x-green\",\"x-blue\",\"x-yellow\",\"x-red\",\"x-grey\",\"x-purple\",\"code-background\",\"cover-shadow-spread\",\"cover-shadow-focus\"";
export var darkBackgroundAltColor = "#222a3a";
export var darkBackgroundColor = "#1a202c";
export var darkBackgroundContrastColor = "rgb(0, 0, 0)";
export var darkCodeBackgroundColor = "#090b0e";
export var darkCoverShadowFocusColor = "rgba(0, 0, 0, 0.6)";
export var darkCoverShadowSpreadColor = "rgba(0, 0, 0, 0.4)";
export var darkHeadingColor = "#71c5d4";
export var darkPrimaryColor = "#098fa8";
export var darkSecondaryColor = "#e28a35";
export var darkTextColor = "rgba(255, 255, 255, 0.87)";
export var darkXBlueColor = "#4BB3D3";
export var darkXGreenColor = "#66d966";
export var darkXGreyColor = "#aaaaaa";
export var darkXPurpleColor = "#c656da";
export var darkXRedColor = "#f5564a";
export var darkXYellowColor = "#f5b318";
export var lightBackgroundAltColor = "#eae4e2";
export var lightBackgroundColor = "#F4F1F0";
export var lightBackgroundContrastColor = "rgb(255, 255, 255)";
export var lightCodeBackgroundColor = "#E8E1DE";
export var lightCoverShadowFocusColor = "rgba(0, 0, 0, 0.2)";
export var lightCoverShadowSpreadColor = "rgba(0, 0, 0, 0.15)";
export var lightHeadingColor = "#077185";
export var lightPrimaryColor = "#077185";
export var lightSecondaryColor = "#D76D07";
export var lightTextColor = "rgba(0, 0, 0, 0.87)";
export var lightXBlueColor = "#22718a";
export var lightXGreenColor = "rgb(0, 128, 0)";
export var lightXGreyColor = "#777777";
export var lightXPurpleColor = "#9c27b0";
export var lightXRedColor = "#f44336";
export var lightXYellowColor = "#ce9309";
export var maxDrawing = "2560px";
export var offBlackBackground = "#1a202c";
export var offWhiteBackground = "#f4f1f0";
export var screenLarge = "1078px";
export var screenMid = "700px";
export var screenXLarge = "1350px";
export var sectionDividerHeight = "120px";