import * as React from "react"
import NavBar from "components/NavBar"
import Footer from "components/Footer"
import { motion } from "framer-motion"
import { camelize } from "utils"
import { useDarkMode } from 'usehooks-ts'
import Color from "color"
import { OverlayScrollbars } from 'overlayscrollbars'

import "styles/main.scss"

const styles = require("./styles.module.scss")
import * as styleDefinitions from 'styles/_exports.module.scss'

// TODO: move this into component
// can't do this now because we can't do require.resolve in gatsby-config.js

type colorVariantsType = {
  light: {[x: string]: string},
  dark: {[x: string]: string},
}

const colorTypes = styleDefinitions.colorTypes.replaceAll('\"', "").split(',').map((x: string) => x.trim())

export const colorVariants: colorVariantsType = {
  light: colorTypes.reduce((acc: {[x: string]: string}, x: string) => {
    acc[`--${x}-color`] = styleDefinitions[camelize(`light-${x}-color`)]
    return acc
  }, {}),
  dark: colorTypes.reduce((acc: {[x: string]: string}, x: string) => {
    acc[`--${x}-color`] = styleDefinitions[camelize(`dark-${x}-color`)]
    return acc
  }, {}),
}

const levels = [3, 5, 10, 20, 40, 60, 80, 100]
levels.forEach((n) => {
  colorVariants.light[`--neutral-color-${n}`] = Color(styleDefinitions.offWhiteBackground).mix(Color('black'), n/100).string()
  colorVariants.dark[`--neutral-color-${n}`] = Color(styleDefinitions.offBlackBackground).mix(Color('white'), n/100).string()
})

type Props = {
  children: React.ReactNode,
}

function handleScroll() {
  // document.documentElement.classList.add()
}

const Layout: React.FC<Props> = ({
  children
}) => {

  const { isDarkMode } = useDarkMode()

  React.useEffect(() => {
    OverlayScrollbars({
      target: document.body,
      // cancel: {
      //   nativeScrollbarsOverlaid: true,
      // }
    }, {
      // showNativeOverlaidScrollbars: true,
      overflow: {
        x: 'hidden',
      },
      scrollbars: {
        autoHide: 'scroll',
        autoHideDelay: 1000,
      },
    })
  }, [])

  return(
    
    // flex container so that the footer is always on the bottom of the screen
    // position: relative so that the navbar can be freely positioned
    <motion.div
      id="main-container"
      animate={(colorVariants[isDarkMode ? 'dark' : 'light'] as any)}
      transition={{ duration: 0.6 }}
    >
      
      <NavBar />

      {/* stretchy part */}
      {children}
      <div className="spacer" />

      {/* fixed part */}
      <Footer />
    
    </motion.div>
    
  )
}

export default Layout

// TODO: scrollbar