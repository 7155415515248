import { graphql, useStaticQuery } from "gatsby"
import deepmerge from "deepmerge"
import { quantile } from "d3"

// https://github.com/TehShrike/deepmerge#arraymerge-example-combine-arrays
export const combineMerge = (target: any, source: any, options: any) => {
	const destination = target.slice()

	source.forEach((item: any, index: number) => {
		if (typeof destination[index] === 'undefined') {
			destination[index] = options.cloneUnlessOtherwiseSpecified(item, options)
		} else if (options.isMergeableObject(item)) {
			destination[index] = deepmerge(target[index], item, options)
		} else if (target.indexOf(item) === -1) {
			destination.push(item)
		}
	})
	return destination
}


function getStaticQueryData() {
  const staticQueryData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
          siteUrl
          author
        }
      }
      allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        nodes {
          name
          publicURL
          childImageSharp {
            wide: gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 2.5)
            tall: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `)
  return staticQueryData
}

export const useSiteMetadata = () => {
  return getStaticQueryData().site.siteMetadata
}

export const useImages = (imageName: string) => {
  return getStaticQueryData().allFile.nodes.find((node: any) => node.name === imageName)
}

// given an array of numbers, compute "minimum" (= Q1 - 1.5IQR), Q1, median, Q3, "maximum" (= Q3 + 1.5IQR)
export const computeBoxPlotStatistics = (data: number[]) => {
  const sortedData = [...data].sort((a, b) => a - b)
  const q1 = quantile(sortedData, 0.25)
  const median = quantile(sortedData, 0.5)
  const q3 = quantile(sortedData, 0.75)
  const iqr = q3 - q1
  const low = Math.max(q1 - 1.5 * iqr, sortedData[0])
  const high = Math.min(q3 + 1.5 * iqr, sortedData[sortedData.length - 1])
  return { low, q1, median, q3, high }
}

export const camelize = (s: string) => s.replace(/-./g, x=>x[1].toUpperCase())

export function shuffle(array: any[]) {
  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}
