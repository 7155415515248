// https://codepen.io/samedkazan/pen/MWbeaQb

import React from "react"

const styles = require("./styles.module.scss")

type Props = {
  handleMenuToggle: React.ChangeEventHandler<HTMLInputElement>,
}

// const Hamburger: React.FC<Props> = 

export default React.forwardRef<HTMLInputElement, Props>(({ handleMenuToggle }, ref) => {

  return(
    <div className={styles.container}>
      <input type="checkbox" ref={ref} onChange={handleMenuToggle} tabIndex={0} />
      <span className={styles.top} />
      <span className={styles.middle} />
      <span className={styles.bottom} />
    </div>
  )

})