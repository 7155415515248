import React from "react"
import { switchLang, getLang } from "components/Link"

const styles = require("./styles.module.scss")

const LangSwitch = () => {

  // prevent rendering of the letter if we get redirected
  const [lang, setLang] = React.useState("")

  React.useEffect(() => {
    setLang(getLang())
  }, [])

  function handleSwitch() {
    switchLang()
    setLang(getLang())
  }

  return(
    <div className={styles.container}>
      <svg viewBox="-30 -10 60 60" onClick={handleSwitch}>
        <circle cx="0" cy="20" r="30" />
        {lang !== "" &&
          <path d={lang === "th"
            ? "M -13 39 L -13 20 C -13 16 -6 14 -6 14 C -6 14 -9 11 -16 11 C -16 11 -13 0 0 0 C 11 0 13 8 13 11 C 13 20 13 30 13 39"
            : "M -13 39 L -8 24 C -3 24 3 24 8 24 C 3 24 -3 24 -8 24 C -6 18 -2 6 0 0 C 0 0 5 15 6 18 C 8 24 13 39 13 39"
          } />
        }
      </svg>
    </div>
  )

}

export default LangSwitch