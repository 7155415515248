import React from "react"
import DayNight from "./DayNight"
import LangSwitch from "./LangSwitch"
import Hamburger from "./Hamburger"
import { useStaticQuery, graphql } from "gatsby"
import Link from "components/Link"
import clsx from "clsx"

const styles = require("./styles.module.scss")

const navItems = [
  {
    label: "Blog",
    link: "/blog",
  },
  {
    label: "About",
    link: "/about",
  },
]

enum ScrollDirection {
  UP,
  DOWN,
  UNKNOWN,
}

type Props = {
}

const NavBar: React.FC<Props> = () => {

  const [isResizing, setIsResizing] = React.useState<boolean>(false)
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false)

  const data = useStaticQuery(graphql`
    query NavBarQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const navbarElement = React.useRef<HTMLDivElement>(null)
  const blackScreenElement = React.useRef<HTMLDivElement>(null)
  const menuElement = React.useRef<HTMLDivElement>(null)
  const hamburgerElement = React.useRef<HTMLInputElement>(null)
  const backgroundElement = React.useRef<HTMLDivElement>(null)
  const prevPageOffset = React.useRef<number>(0)
  const prevScrollDirection = React.useRef<ScrollDirection>(ScrollDirection.UNKNOWN)
  const lastTurnPos = React.useRef<number>(0)
  const navbarTop = React.useRef<number>(0)

  function setNavBarOpacity(y: number) {
    if (!backgroundElement.current)
      return
    const opacity = 1 - Math.min(Math.max(y - 100, 0), 300) / 300 * 0.3
    // const hexOpacity = (Math.round(opacity * 255)).toString(16)
    backgroundElement.current.style.opacity = opacity.toString()
  }

  const setHeader = React.useCallback((position: string, top: number) => {
    navbarElement.current?.style.setProperty('--navbar-position', position)
    navbarElement.current?.style.setProperty('--navbar-top', top + "px")
    navbarTop.current = top
  }, [navbarElement])

  function handleScroll() {

    if (!navbarElement.current)
      return

    const navbarHeight = navbarElement.current.offsetHeight
    const curPageOffset = Math.max(window.scrollY, 0)
    setNavBarOpacity(curPageOffset)

    if (prevPageOffset.current > curPageOffset) {
    // scroll up
      if (prevScrollDirection.current === ScrollDirection.DOWN) {
      // previous scroll was down
        prevScrollDirection.current = ScrollDirection.UP
        if (curPageOffset - lastTurnPos.current > navbarHeight) {
        // we have scrolled down all the way through the navbar's height
        // so hide it on top of the view
          setHeader('absolute', curPageOffset - navbarHeight)
        }
        lastTurnPos.current = curPageOffset
      }
      else {
        // continue to scroll up
        const margin = prevPageOffset.current - curPageOffset
        if (lastTurnPos.current - curPageOffset > navbarHeight - margin || curPageOffset < navbarTop.current) {
          // can hide
          setHeader('fixed', 0)
          navbarTop.current = curPageOffset
        }
      }
    }
    else if (prevPageOffset.current < curPageOffset) {
    // scroll down
      // if (navbarElement.current.classList.contains("menu-hide")) {
        if (prevScrollDirection.current === ScrollDirection.UP) {
        // previously scroll up, now scroll down
          prevScrollDirection.current = ScrollDirection.DOWN
          if (lastTurnPos.current - curPageOffset > navbarHeight) {
            setHeader('absolute', curPageOffset)
          }
          else if (lastTurnPos.current < curPageOffset) {
            setHeader('absolute', Math.max(0, curPageOffset - navbarHeight))
          }
          lastTurnPos.current = curPageOffset
        }
        else if (prevScrollDirection.current === ScrollDirection.UNKNOWN) {
          prevScrollDirection.current = ScrollDirection.DOWN
          setHeader('absolute', curPageOffset)
          lastTurnPos.current = curPageOffset
        }
        else {
          setHeader('absolute', navbarTop.current)
        }
      // }
    }
    prevPageOffset.current = curPageOffset
  }

  function handleMenuToggle(e: React.ChangeEvent<HTMLInputElement>) {
    setIsMenuOpen(e.target.checked)
  }

  React.useEffect(() => {
    if (isMenuOpen) {
      // prevent scrolling
      const scrollY = window.scrollY
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollY}px`
      // we set zIndex here so when we debug stuff we can see through the black screen
      blackScreenElement.current.style.zIndex = "100";
    }
    else {
      hamburgerElement.current.checked = false
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      // @ts-ignore
      window.scrollTo({top: parseInt(scrollY || '0') * -1, behavior: "instant"});
      setHeader('fixed', 0)
      blackScreenElement.current.style.zIndex = "-1";
    }
  }, [isMenuOpen])

  // need this to prevent scroll event from firing during resizing
  // https://stackoverflow.com/questions/45635008/how-can-i-prevent-onscroll-function-to-trigger-during-resize

  function handleScrollTimeout() {
    setTimeout(() => {
      if (!isResizing)
        handleScroll()
    }, 0)
  }

  function handleResize() {
    setIsResizing(true)
    setTimeout(() => {
      setIsResizing(false)
    }, 100)
  }

  React.useEffect(() => {
    setNavBarOpacity(window.pageYOffset)
    menuElement.current.style.setProperty('--menu-height', `${navItems.length * 1.5 + 1}rem`)
    window.addEventListener('scroll', handleScrollTimeout)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('scroll', handleScrollTimeout)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return(
    <>
      <header id="navbar" ref={navbarElement} className={clsx(isMenuOpen && "showMenu")}>
      {/* the main portion of the navbar; exclude dropdown menu */}
        <div ref={backgroundElement} className={styles.backgroundFill} />
        <div className={styles.container}>
          <div className={clsx(styles.flexContainer, "cap-width")}>
            <Link to="/" className="navTitle">{data.site.siteMetadata.title}</Link>
            <div className="spacer" />
            <nav>
              {navItems.map((item, i) => 
                <Link
                  to={item.link}
                  key={i}
                  className={styles.navItem}
                  activeClassName={styles.active}
                  partiallyActive={true}
                >
                  {item.label}
                </Link>
              )}
            </nav>
            <LangSwitch />
            <DayNight />
            <Hamburger handleMenuToggle={handleMenuToggle} ref={hamburgerElement} />
          </div>
        </div>
        <nav className={clsx(styles.menu, "cap-width")} ref={menuElement}>
          {navItems.map((item, i) =>
            <Link
              to={item.link}
              key={i}
              className={styles.menuItem}
              activeClassName={styles.active}
              partiallyActive={true}
              onClick={() => setIsMenuOpen(false)}
            >
              {item.label}
            </Link>
          )}
        </nav>
      </header>
      <div ref={blackScreenElement} id="navbar-blackscreen" />
      <div id="navbar-placeholder" />
    </>
  )

}

export default NavBar